<template>
  <div class="marquee" v-if="marquee.show_error || marquee.show">
    <marquee>
      {{ localizedText }}
    </marquee>
  </div>
</template>

<script>
import i18n from "../../i18n";
import axios from "axios";
export default {
  name: "MarqueeComponent",
  data: () => ({
    i18n,
    marquee: {
      show: false,
      show_error: false,
      text_pl: "",
      text_en: "",
      text_de: "",
      error_msg_en: "",
      error_msg_pl: "",
      error_msg_de: ""
    }
  }),
  async created() {
    this.setMarqueeInfo();
  },
  computed: {
    localizedText() {
      const locale = i18n.locale;
      if (this.marquee.show_error) {
        return this.marquee[`error_msg_${locale}`];
      }
      return this.marquee[`text_${locale}`] || i18n.t("noMarqueeText");
    }
  },
  methods: {
    async setMarqueeInfo() {
      const urlBase = "https://api.ozparts.eu";
      const website = "ozperformance";
      const url = `${urlBase}/marquees/${website}`;

      try {
        const res = await axios.get(url);
        if (res.data.show_error || res.data.show) {
          this.marquee = res.data;
        }
      } catch {
        alert("Something went wrong with banner!");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.marquee {
  background-color: var(--red);
  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 2px 0;
  margin-bottom: -5px;
}
</style>
