import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import i18n from "./../i18n";

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cookiesConsent: {
      accepted: false,
      types: ["mandatory"]
    },
    showCookiesManager: false,
    lang: "en",
    user: null,
    basketQuantity: 0,
    welcome: false,
    showMobileMenu: false,
    urlBeforeLogin: null,
    cart_id: null,
    userCountry: null,
    currency: null,
    userRegionData: ""
  },
  getters: {
    getUser: state => state.user,
    isUserLogged: state => !!state.user,
    getToken: state => state.user && state.user.token,
    getBasketCount: state => state.basketQuantity,
    currency: state => state.currency
  },
  mutations: {
    setUserCountry(state, payload) {
      state.userCountry = payload;
    },
    setUserRegionData(state, payload) {
      state.userRegionData = payload;
    },
    setCurrency(state, payload) {
      state.currency = payload;
    },
    setCookiesConsent(state, payload) {
      state.cookiesConsent = payload;
    },
    setCookiesManager(state, payload) {
      state.showCookiesManager = payload;
    },
    setLang(state, payload) {
      state.lang = payload;
      i18n.locale = payload;
    },
    setUrlBeforeLogin(state, payload) {
      state.urlBeforeLogin = payload;
    },
    setMobileMenu(state, payload) {
      state.showMobileMenu = payload;
    },
    logIn(state, payload) {
      state.user = payload;
      state.basketQuantity = payload.quantity;
    },
    logOut(state) {
      state.user = null;
      state.basketQuantity = 0;
    },
    updateQuantity(state, payload) {
      state.basketQuantity = payload;
    },
    prepareMessage(state) {
      state.welcome = true;
    },
    turnOffMessage(state) {
      state.welcome = false;
    },
    setCartId(state, payload) {
      state.cart_id = payload;
    },
    clearCart(state) {
      state.cart_id = null;
      state.basketQuantity = 0;
    }
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin]
});
