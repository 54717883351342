import { render, staticRenderFns } from "./CardDetails.vue?vue&type=template&id=2f96c440&scoped=true&"
import script from "./CardDetails.vue?vue&type=script&lang=js&"
export * from "./CardDetails.vue?vue&type=script&lang=js&"
import style0 from "./CardDetails.vue?vue&type=style&index=0&id=2f96c440&prod&lang=scss&scoped=true&"
import style1 from "./CardDetails.vue?vue&type=style&index=1&id=2f96c440&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.3_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f96c440",
  null
  
)

export default component.exports