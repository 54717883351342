const dba = require("./assets/kolecka/Kolecka_DBA.png");
const dbaWebp = require("./assets/kolecka/Kolecka_DBA.webp");
const xp = require("./assets/kolecka/XP_1.png");
const xpWebp = require("./assets/kolecka/XP_1.webp");
const hawk = require("./assets/kolecka/o-hawk.jpg");
const hawkWebp = require("./assets/kolecka/o-hawk.webp");
const xforce = require("./assets/kolecka/xforce1_lighter2.png");
const xforceWebp = require("./assets/kolecka/xforce1_lighter2.webp");
const pedders = require("./assets/kolecka/o-pedders.jpg");
const peddersWebp = require("./assets/kolecka/o-pedders.webp");
const turbosmart = require("./assets/kolecka/o-turbosmart.jpg");
const turbosmartWebp = require("./assets/kolecka/o-turbosmart.webp");
const whiteline = require("./assets/kolecka/o-whiteline.jpg");
const whitelineWebp = require("./assets/kolecka/o-whiteline.webp");
const acl = require("./assets/kolecka/o-acl.png");
const aclWebp = require("./assets/kolecka/o-acl.webp");

export const brands = [
  {
    header: "dba",
    img: dba,
    imgWebp: dbaWebp,
    showInDeadstock: true
  },
  {
    header: "xclutch",
    img: xp,
    imgWebp: xpWebp,
    showInDeadstock: true
  },
  {
    header: "hawk",
    img: hawk,
    imgWebp: hawkWebp,
    showInDeadstock: true
  },
  {
    header: "xforce",
    img: xforce,
    imgWebp: xforceWebp,
    showInDeadstock: true
  },
  {
    header: "pedders",
    img: pedders,
    imgWebp: peddersWebp,
    showInDeadstock: true
  },
  {
    header: "turbosmart",
    img: turbosmart,
    imgWebp: turbosmartWebp
  },
  {
    header: "whiteline",
    img: whiteline,
    imgWebp: whitelineWebp,
    showInDeadstock: true
  },
  {
    header: "acl",
    img: acl,
    imgWebp: aclWebp,
    showInDeadstock: true
  }
];

export const MANUFACTURER = {
  ACS: "5f9bda55dcdf6b0c04f18789",
  DBA: "5f9bda55dcdf6b0c04f1878c",
  PEDDERS: "5f9bda55dcdf6b0c04f1878b",
  XFORCE: "5f9bda55dcdf6b0c04f1878d",
  HAWK: "5f9bda56dcdf6b0c04f18790",
  TURBOSMART: "5f9bda56dcdf6b0c04f18791",
  ACL: "5f9bda56dcdf6b0c04f1878e",
  WHITELINE: "5f9bda55dcdf6b0c04f1878a",
  REDARC: "5f9bdaf85e3dc023c4682867",
  SABER: "60c063843f41452f00573370",
  EUROSPARES: "5f9bda56dcdf6b0c04f18792",
  OSRAM: "5f9bda56dcdf6b0c04f18793",
  OTHER: "5f9bda56dcdf6b0c04f18794",
  AEROFLOW: "60c0672c3f41452f00573373"
};

export const WAREHOUSE_LOCATION = {
  UK: "61e14c6ae4a35a668517a8fe",
  EU: "5e0dbaba9e33df43f0b3a495",
  NL: "66e04fcba16b2e1e7d873be8"
};

export const DBA_EXCLUDED = [
  "DBA52068.1LXD",
  "DBA52068.1RXD",
  "DBA092SL",
  "DBA092SR",
  "DBA174SL",
  "DBA174SR",
  "DBA2306SL",
  "DBA2306SR",
  "DBA2307SL",
  "DBA2307SR",
  "DBA2311SL",
  "DBA234TSL",
  "DBA234TSR",
  "DBA2801SL",
  "DBA2801SR",
  "DBA2839SL",
  "DBA2839SR",
  "DBA2950SL",
  "DBA2950SR",
  "DBA3290EL",
  "DBA3290LS",
  "DBA3290RS",
  "DBA3417EL",
  "DBA3417LS",
  "DBA3417RS",
  "DBA3418EL",
  "DBA3418LS",
  "DBA3418RS",
  "DBA3424EL",
  "DBA3424LS",
  "DBA3424RS",
  "DBA3698OEXL",
  "DBA4087SL",
  "DBA4087SR",
  "DBA4088SL",
  "DBA4088SR",
  "DBA4092SL",
  "DBA4092SR",
  "DBA4093SL",
  "DBA4093SR",
  "DBA42030CSL",
  "DBA42030CSR",
  "DBA42031CSL",
  "DBA42031CSR",
  "DBA42048SL",
  "DBA42048SR",
  "DBA42049SL",
  "DBA42049SR",
  "DBA42092SL",
  "DBA42092SR",
  "DBA42093SL",
  "DBA42093SR",
  "DBA42094SL",
  "DBA42094SR",
  "DBA4214SL",
  "DBA4214SR",
  "DBA42224SL",
  "DBA42306SL",
  "DBA42306SR",
  "DBA42307SL",
  "DBA42307SR",
  "DBA42311SL",
  "DBA42655SL",
  "DBA42659SL",
  "DBA42806SL",
  "DBA4526SL",
  "DBA4526SR",
  "DBA4528SL",
  "DBA4528SR",
  "DBA4543SL",
  "DBA4543SR",
  "DBA4547SL",
  "DBA4547SR",
  "DBA4573SL",
  "DBA4573SR",
  "DBA4661SL",
  "DBA4661SR",
  "DBA4840SL",
  "DBA4840SR",
  "DBA4841SL",
  "DBA4841SR",
  "DBA4878SL",
  "DBA4878SR",
  "DBA5003.1SL",
  "DBA5003.1SR",
  "DBA52030BLKCSL",
  "DBA52030BLKCSL-8",
  "DBA52030BLKCSR",
  "DBA52030BLKCSR-8",
  "DBA52068.1LS",
  "DBA52068.1RS",
  "DBA52068BLKLS",
  "DBA52068BLKLXS",
  "DBA52068BLKRS",
  "DBA52068BLKRXS",
  "DBA52072.1CSL",
  "DBA52072.1CSR",
  "DBA52072BLKCSL",
  "DBA52072BLKCSR",
  "DBA52230BLKCSL",
  "DBA52230BLKCSR",
  "DBA52231.1LS",
  "DBA52231.1RS",
  "DBA52231BLKLS",
  "DBA52231BLKRS",
  "DBA52356BLKLS",
  "DBA52356BLKRS",
  "DBA52370.1LS",
  "DBA52370.1RS",
  "DBA52371.1LS",
  "DBA52371.1RS",
  "DBA52379.1LS",
  "DBA52379.1RS",
  "DBA52379BLKLS",
  "DBA52379BLKLXD",
  "DBA52379BLKRS",
  "DBA52379BLKRXD",
  "DBA52385.1LS",
  "DBA52385.1R",
  "DBA52385.1RS",
  "DBA52608BLKLS",
  "DBA52608BLKLXD",
  "DBA52608BLKLXS",
  "DBA52608BLKRS",
  "DBA52608BLKRXD",
  "DBA52608BLKRXS",
  "DBA52770.1LS",
  "DBA52770.1RS",
  "DBA52828.1LS",
  "DBA52828.1RS",
  "DBA52828BLKLS",
  "DBA52828BLKRS",
  "DBA52903BLKLS",
  "DBA52906.1LS",
  "DBA52906.1RS",
  "DBA52908.1LS",
  "DBA52908.1RS",
  "DBA52908BLKLS",
  "DBA52908BLKRS",
  "DBA52914.1LS",
  "DBA52914.1RS",
  "DBA52921.1LS",
  "DBA52921.1RS",
  "DBA52923.1L",
  "DBA52923.1LS",
  "DBA52923.1R",
  "DBA52923.1RS",
  "DBA52933.1LS",
  "DBA52933.1RS",
  "DBA52972.1LS",
  "DBA52972.1RS",
  "DBA52974.1LS",
  "DBA52974.1RS",
  "DBA5355.1LS",
  "DBA5355.1RS",
  "DBA57888.1LS",
  "DBA57888.1RS",
  "DBA57904.1LS",
  "DBA57904.1RS",
  "DBA59382.1LS",
  "DBA59382.1RS",
  "DBA59384.1LS",
  "DBA59384.1RS",
  "DBA624SL",
  "DBA624SR",
  "DBA627SL",
  "DBA627SR",
  "DBA663SL",
  "DBA663SR",
  "DBA710SL",
  "DBA710SR",
  "DBA741SL",
  "DBA741SR",
  "DBA780SL",
  "DBA780SR",
  "DBA788SL",
  "DBA788SR",
  "DBA52385.1L",
  "DBA3424ER",
  "DBA3698OEXR",
  "DBA3290ER",
  "DBA3417ER",
  "DBA3418ER",
  "DBA3554EL",
  "DBA3554ER",
  "DBA52371.1L",
  "DBA52914.1L",
  "DBA3274LS",
  "DBA3274RS",
  "DBA3262LS",
  "DBA3262EL",
  "DBA3276EL",
  "DBA3084EL",
  "DBA3272OEXL",
  "DBA3602OEXL",
  "DBA3144EL",
  "DBA52914.1R",
  "DBA3262RS",
  "DBA3262ER",
  "DBA3276ER",
  "DBA3084ER",
  "DBA3272OEXR",
  "DBA3602OEXR",
  "DBA3144ER",
  "DBA52396BLKCSR",
  "DBA52069BLKOESR",
  "DBA42246CXR",
  "DBA42394CXR",
  "DBA43124R",
  "DBA3274ER",
  "DBA43124L",
  "DBA42246CXL",
  "DBA42394CXL",
  "DBA3274EL"
];
